import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { LoadingScreen } from '@/ui/container/common/loadingScreen';
import LoginScreen from '@/ui/screens/loginScreen';

import { getCookie } from '@/utils/cookie';

export default function LoginPage() {
    const authToken = getCookie('authToken');
    const router = useRouter();

    useEffect(() => {
        if (authToken) router.push('/dashboard');
    }),
        [authToken];

    if (authToken) {
        return <LoadingScreen />;
    }
    return <LoginScreen />;
}
