import axios from 'axios';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import styled from 'styled-components';

import LoginLayout from '@/ui/layout/login';

import { setCookie } from '@/utils/cookie';
import { isValidPhoneNumber } from '@/utils/others';
import { SCREEN_NAME } from '@/utils/routingUtils';
import { EVENT_TYPE, trackEvent } from '@/utils/trackingUtils';
import { getBackendURL } from '@/utils/urlUtils';

export const getOTP = async (number: number) => {
    return await axios
        .post(`${getBackendURL(false)}/api/v1/auth/send-otp`, {
            internationalPrefix: 91,
            number: parseInt(number as any),
        })
        .then((res) => res.data);
};

export const verifyOTP = async (number: number | string, otp: number) => {
    return await axios
        .post(`${getBackendURL(false)}/api/v1/auth/verify-otp`, {
            phoneNumber: {
                internationalPrefix: 91,
                number: parseInt(number as any),
            },
            otp: parseInt(otp as any),
        })
        .then((res) => res.data);
};

export const MobileAdaptableErroBox = styled.div`
    width: 224px;
    @media screen and (max-width: 680px) {
        width: 100%;
        max-width: 320px;
        font-size: 15px;
    }
`;

export default function LoginScreen() {
    const [number, setNumber] = useState('');
    const [state, setState] = useState<any>({
        type: 'phone',
        error: null,
        loading: false,
    });
    const [otp, setOTP] = useState('');
    const router = useRouter();
    const { query } = router;

    return (
        <LoginLayout>
            <Head>
                <title> Login | Investmint</title>
            </Head>
            {state.type === 'phone' && (
                <React.Fragment>
                    <MobileAdaptableInput
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        type="tel"
                        autoComplete="tel"
                        placeholder="Enter phone no."
                        className="mt-28"
                    />

                    <MobileAdaptableButton
                        onClick={() => {
                            getOTP(Number(number));
                            setState({ type: 'otp' });
                            trackEvent(EVENT_TYPE.BUTTON_CLICKED, {
                                button_id: 'get_otp',
                                from_screen: SCREEN_NAME.login,
                            });
                        }}
                        autocomplete="off"
                        name="otp"
                        type="primary"
                        disabled={isValidPhoneNumber(number)}
                        className="mt-12 flex items-center justify-center"
                    >
                        <span className="ml-6 font-600">Get OTP</span>
                    </MobileAdaptableButton>
                </React.Fragment>
            )}
            {state.type === 'otp' && (
                <React.Fragment>
                    <MobileAdaptableInput
                        value={otp}
                        onChange={(e) => setOTP(e.target.value)}
                        placeholder="Enter otp"
                        // Detect otp input
                        type="text"
                        pattern="\d*"
                        className="mt-28"
                    />

                    <MobileAdaptableButton
                        onClick={async () => {
                            try {
                                trackEvent(EVENT_TYPE.BUTTON_CLICKED, {
                                    button_id: 'verify_otp',
                                    from_screen: SCREEN_NAME.phone_verification,
                                });
                                // @Todo - Move to react query for loading
                                setState({ ...state, loading: true, error: null });
                                const { authToken } = await verifyOTP(Number(number), Number(otp));
                                setCookie('authToken', authToken, 30);
                                trackEvent(EVENT_TYPE.LOGIN);
                                if (query?.redirect) {
                                    router.push(query?.redirect as string);
                                } else {
                                    router.push('/dashboard');
                                }
                            } catch {
                                setState({ ...state, error: 'Invalid OTP. Please try again.', loading: false });
                            }
                        }}
                        type={number.length < 10 ? 'disabled' : 'primary'}
                        className="mt-12 flex items-center justify-center"
                        disabled={number.length < 10 || state.loading}
                    >
                        <span className="ml-6 font-600">{state.loading ? 'Verifying' : 'Verify otp'}</span>
                    </MobileAdaptableButton>

                    {state.error && <MobileAdaptableErroBox className="mt-8 flex items-center text-red-error">{state.error}</MobileAdaptableErroBox>}

                    <ResendOtp className="mt-20 flex justify-between text-12 text-grey-secondary underline">
                        <div
                            onClick={() => {
                                trackEvent(EVENT_TYPE.BUTTON_CLICKED, {
                                    button_id: 'edit_phone_number',
                                    from_screen: SCREEN_NAME.phone_verification,
                                });
                                setState({ type: 'phone' });
                            }}
                            className={'hover'}
                        >
                            Change number
                        </div>
                        <div
                            onClick={() => {
                                trackEvent(EVENT_TYPE.BUTTON_CLICKED, {
                                    button_id: 'resend_otp',
                                    from_screen: SCREEN_NAME.phone_verification,
                                });
                                getOTP(Number(number));
                                alert('New otp was sent to your number');
                            }}
                        >
                            Resend OTP
                        </div>
                    </ResendOtp>
                </React.Fragment>
            )}
        </LoginLayout>
    );
}

const ResendOtp = styled.div`
    width: 100%;
    height: 48px;
    max-width: 220px;
    @media screen and (max-width: 680px) {
        width: 100%;
        max-width: 320px;
    }
`;

const Input = styled.input`
    width: 224px;
    height: 40px;
    background: #ffffff;

    border-radius: 8px;
    padding: 12px;
    outline: none;

    box-shadow: rgb(0 0 0 / 12%) 0px 0px 0px 0px, rgb(61 59 53 / 16%) 0px 0px 0px 1px, rgb(61 59 53 / 8%) 0px 2px 5px 0px;

    :focus {
        box-shadow: rgb(58 152 255 / 36%) 0px 0px 0px 3px, rgb(61 59 53 / 16%) 0px 0px 0px 1px;
    }

    :hover {
        opacity: 0.8;
    }
`;

const Button = styled.button<any>`
    width: 228px;
    height: 40px;
    left: 656px;
    top: 340px;

    // css to show not allowed icon

    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? '.7' : '1')};
    ${(props) => (props.type === 'primary' ? 'background: #5667FF; color: white;' : '')};

    border: 1px solid #eceef4;
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    :hover {
        opacity: 0.8;
    }
`;

export const MobileAdaptableInput = styled(Input)`
    @media screen and (max-width: 680px) {
        width: 100%;
        height: 48px;
        max-width: 320px;
        font-size: 15px;
    }
`;

export const MobileAdaptableButton = styled(Button as any)`
    @media screen and (max-width: 680px) {
        width: 100%;
        height: 48px;
        max-width: 320px;
        font-size: 14px;
    }
`;
