import styled from 'styled-components';

import { Logo, MagnifyIcon, RuppeIcon, UserIcon } from '@/constants/icons';

const Card = styled.div`
    width: 459px;
    max-width: 100%;
    margin-top: -56px;

    height: 544px;

    background: #ffffff;
    outline: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;

    margin-top: -20px;

    overflow: hidden;
    color: #202124;

    @media screen and (max-width: 640px) {
        margin-top: 0px;
        outline: 0px solid rgba(0, 0, 0, 0.1);

        height: unset;
    }
`;
const HomepageCard = styled(Card)`
    font-size: 13px;
    font-weight: 500;
    @media screen and (max-width: 680px) {
        #logo {
            zoom: 1.4;
        }
    }
`;

export default function LoginLayout({ children }: { children: React.ReactNode }) {
    return (
        <>
            <GreyBackground className="custom-grey flex flex-col items-center justify-center md:justify-start">
                <HomepageCard>
                    <Image className="flex flex-col items-center justify-center px-12 text-12">
                        <Logo />
                        <div className="mt-32 text-14 font-700 md:text-17">Systematic trading for everyone</div>
                        <div className="mt-8 text-center tracking-1 md:text-14 ">Start now with India's first Signal-based Trading app</div>
                    </Image>

                    <div className="mt-24 flex flex-col items-center">{children}</div>
                </HomepageCard>

                <div className="mt-32 flex items-center gap-16 px-12 md:mt-120" style={{ color: '#696969' }}>
                    <div className="flex items-center gap-4">
                        <UserIcon className="mr-4" /> <strong>100,000+</strong> users
                    </div>
                    <div className="flex items-center gap-4">
                        <RuppeIcon className="mr-4" /> <strong>Zero</strong> fees
                    </div>
                    <div className="flex items-center gap-4">
                        <MagnifyIcon className="mr-4" /> <strong>100%</strong> safe
                    </div>
                </div>
                <div className="mt-24 px-12 text-center text-10 leading-7" style={{ color: '#696969' }}>
                    Toolbox Technologies Private Limited | CIN: U72900KA2021PTC155011
                    <br />
                    JA 101, Salarpuria Greenage,Hosur Main Road,Bommanahalli, Bengaluru 560068
                </div>
            </GreyBackground>
        </>
    );
}

interface BtnProps {
    type: 'primary';
    display: boolean;
}

const Button = styled.button<BtnProps>`
    width: 228px;
    height: 40px;
    left: 656px;
    top: 340px;
    font-size: 12px;

    // css to show not allowed icon

    // @ts-ignore
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    // @ts-ignore
    ${(props) => (props.type === 'primary' ? 'background: #5667FF; color: white;' : '')};

    border: 1px solid #eceef4;
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    :hover {
        opacity: 0.8;
    }
`;

export const MobileAdaptableButtion = styled(Button)`
    @media screen and (max-width: 680px) {
        width: 100%;
        height: 48px;
        max-width: 320px;
        font-size: 14px;
    }
`;

const Image = styled.div`
    width: 100%;
    height: 178px;

    background-image: url('/assets/img/gradient/gradient.png');
    background-size: contain;
    background-size: cover;

    background-position-y: -50%;
    @media screen and (max-width: 640px) {
        height: 220px;
    }
`;

const GreyBackground = styled.div`
    background: #e2e2e2;
    min-height: 100vh;
    width: 100%;
    @media screen and (max-width: 640px) {
        background: #fff;
    }
`;
